import SectionNewsletter from "./SectionNewsletter";
import LogoUncoveredLight from "assets/svgs/uncovered-light.svg";
import Copyright from "./Copyright";
import MenuItem from "./MenuItem";
import SocialItem from "./SocialItem";
import FacebookIcon from "assets/svgs/facebook.svg";
import TwitterIcon from "assets/svgs/twitter.svg";
import YoutubeIcon from "assets/svgs/youtube-symbol-svgrepo-com.svg";
import { useUser } from "@auth0/nextjs-auth0/client";

export default function Footer({showEmailSignup = true}) {
  const { user, isLoading } = useUser();
  
  return (
    <footer className="bg-gray-600 text-white">
      {!isLoading && !user && showEmailSignup && (<SectionNewsletter />)}
      <div className="max-w-6xl	mx-auto px-4 xl:px-0 md:pt-20 pt-10 mdL:pb-12 pb-8">
        <div className="max-w-xxs md:mb-10 mb-5">
          <LogoUncoveredLight />
        </div>
        <div className="flex flex-wrap -mx-2">
          <div className="md:w-1/4 w-full px-2 mb-8 md:mb-0">
            <p className="text-gray-400 text-sm leading-7">
              Uncovered is where the most passionate true crime enthusiasts can
              learn from and teach others. Dig deeper into the stories you&apos;ve
              heard before, and encounter many others you haven&apos;t, in ways that
              actually help.
            </p>
          </div>
          <div className="md:w-9/12 w-full px-2">
            <div className="md:pl-20">
              <div className="flex flex-wrap -mx-2">
                <div className="sm:w-1/3 w-full px-2 mb-8 sm:mb-0">
                  <ul className="space-y-2">
                    <MenuItem text="Cold Cases" href="/cases" />
                    <MenuItem
                      text="Community"
                      href="https://community.uncovered.com"
                    />
                    <MenuItem
                      text="Resources"
                      href="https://uncovered.com/resources/"
                    />
                    <MenuItem text="About" href="/about" />
                  </ul>
                </div>
                <div className="sm:w-1/3 w-full px-2 mb-8 sm:mb-0">
                  <ul className="space-y-2">
                    <MenuItem text="Site Map" href="/sitemap" />
                    <MenuItem
                      text="Privacy Policy"
                      href="https://uncovered.com/privacy-policy"
                    />
                    <MenuItem
                      text="Terms & Use"
                      href="https://uncovered.com/terms-of-service"
                    />
                  </ul>
                </div>
                <div className="sm:w-1/3 w-full px-2">
                  <h5 className="text-xl mb-4">Follow Us</h5>
                  <div className="flex space-x-4">
                    <SocialItem link="https://twitter.com/uncovered">
                      <span className="w-4">
                        <TwitterIcon />
                      </span>
                    </SocialItem>
                    <SocialItem link="https://www.facebook.com/uncovered.coldcases">
                      <span className="w-3">
                        <FacebookIcon />
                      </span>
                    </SocialItem>
                    <SocialItem link="https://www.youtube.com/channel/UCELUiHdYr26CCZcE1j55YOA">
                      <span className="w-2 -ml-2.5">
                        <YoutubeIcon />
                      </span>
                    </SocialItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Copyright />
      </div>
    </footer>
  );
}
