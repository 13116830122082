import EmailSignup from "@components/EmailSignup";
import EmailOutline from "assets/svgs/emailoutline.svg";
import style from "components/footer/style.module.css";
import TheCitizenDetective from "assets/svgs/the-citizen-detective-foooter.svg";

export default function SectionNewsletter({
  className,
}: {
  className?: string;
}) {
  return (
    <section className={`bg-black md:py-8 py-6 ${className}`}>
      <div className="max-w-6xl	mx-auto px-4 xl:px-0">
        <div className="flex flex-wrap -mx-2 items-center">
          <div className="md:w-1/2 w-full px-2 mb-6 md:mb-0">
            <div className="relative w-full md:max-w-md">
              <div className="flex justify-center">
                <TheCitizenDetective className="max-w-xs h-28 w-4/5 mb-4 md:mb-0" />
              </div>
              <h5 className=" text-white text-sm text-center md:text-left">
                Stay on top of all things cold cases, advocacy, and true crime
                with our twice per week newsletter
              </h5>
            </div>
          </div>
          <div className="md:w-1/2 w-full px-2">
            <div className={style.mailchimpform}>
              <span className="absolute transform left-6">
                <EmailOutline />
              </span>
              <EmailSignup signupSource={"footer"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
