import React from "react";

export default function SocialItem({ link, children }) {
  return (
    <a
      className="yellow-100 w-10 h-10 rounded-full hover:bg-white transition duration-300 bg-yellow-100 text-black hover:test-white text-2xl flex items-center justify-center"
      href={link}
    >
      {children}
    </a>
  );
}
