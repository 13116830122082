import Link from "next/dist/client/link";

export default function MenuItem({ href, text }) {
  return (
    <li>
      <Link href={href}>
        <a className="text-sm transition hover:opacity-80 duration-300">
          {text}
        </a>
      </Link>
    </li>
  );
}
