import { useState } from "react";

const redirectUrl = process.env.NEXT_PUBLIC_EMAIL_SIGNUP_REDIRECT_URL;

export default function EmailSignup({ signupSource, isAutoFocused = false }) {

	const [email, setEmail] = useState("");
	const [responseMessage, setResponseMessage] = useState(null);
	const [isError, setIsError] = useState(false);
	const [isSignupLoading, setIsSignupLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(email === "");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsDisabled(true);
		setIsSignupLoading(true);

		const emailBody = {
			email: email,
		};

		if (signupSource) {
			emailBody.source = signupSource;
		}

		const emailSignupResponse = await fetch(`/api/email-signup`, {
			method: "POST",
			body: JSON.stringify(emailBody),
			mode: "same-origin",
			headers: {
				'Content-Type': 'application/json'
			},
		});

		if (emailSignupResponse.ok) {
			setResponseMessage("Thanks for subscribing!");
			setIsSignupLoading(false);
			if (redirectUrl && window && window.location && window.location.href) {
				window.location.href = `${redirectUrl}?email=${email}`;
			}
		} else {
			setIsError(true);
			setResponseMessage("Error signing up.");
			setIsSignupLoading(false);
			setIsDisabled(false);
			const errorResponse = await emailSignupResponse.json();
			console.error(errorResponse);
		}
	};

	return (
	<form className="text-center w-full" onSubmit={handleSubmit}>
		<input
			{...isAutoFocused && { autoFocus: true }}
			placeholder="Type your email"
			type="email"
			value={email}
			onChange={(evt) => {
				setEmail(evt.target.value);
				setIsDisabled(evt.target.value === "");
			}}
		/>
		<button
			className=""
			disabled={isDisabled}
			type="submit"
		>
			Subscribe
			
			{isSignupLoading && (
				<i className="animate-spin rounded-full h-4 w-4 ml-4 border-b-2 border-white-900" />
			)}
		</button>
			{responseMessage && (
			<>
				<div>
						<span className={`my-4 px-2 rounded bg-gray-100 text-center font-semibold ${isError ? "text-red-500" : "text-black"} text-xs`}>
							{responseMessage}
						</span>
				</div>
			</>
		)}
	</form>
	);
}